<template>
    <div>
        <title-bar :title="$t('navigations.emails')"/>
        <div v-if="rows" class="custom-table">
            <table-actions
                :actions="['filterSelect', 'search', 'addBtn', 'perPage']"
                :addBtnText="$t('messages.sendEmail')"
                addBtnLink="/emails/send"
                :option="filterOption"
                :options="filterOptions"
                @onSearchInput="inputChange"
                @filterSelectChange="filterSelectChange"
                :defaultPerPage="perPage"
            />
            
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:templateName="data">
                    {{ $helper.getEnumTranslation("email_template_name", data.value.templateName, $i18n.locale) }}
                </template>
                <template v-slot:subject="data">
                    {{ data.value.subject }}
                </template>
                <template v-slot:createdAt="data">
                    {{ $helper.formatDate(data.value.createdAt) }}, {{ $helper.formatTime(data.value.createdAt) }}
                </template>
                <template v-slot:read="data">
                    <b-badge :variant="viewedClass(data.value.viewed)">{{ data.value.viewed ? 'YES' : 'NO' }}</b-badge>
                </template>
                <template v-slot:status="data">
                    <b-badge :variant="statusClass(data.value.status)">{{ data.value.status }}</b-badge>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <custom-btn icon="eye"
                                    :path="$helper.getViewUrl('emails', data.value.id)"
                        />
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import {getUserData} from "@/auth/utils";
import ListUtils from "@/mixins/ListUtils";
import CustomBtn from "@/components/Buttons/CustomBtn";

export default {
    name: "AdminEmails",
    components: {
        CustomBtn,
        TableLite,
        TablePagination,
        TableActions,
        TitleBar
    },
    props: {
        widget: {type: Boolean, default: false}
    },
    mixins: [ResourceUtils, ListUtils],
    data() {
        return {
            isLoading: false,
            emailTemplate: null,
            rows: null,
            perPage: this.widget ? 5 : this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            filterOption: {
                id: null,
                label: "All"
            },
            filterOptions: [],
            sortable: {
                order: "createdAt",
                sort: "desc",
            },
            columns: [
                {
                    label: this.$t('forms.templateName'),
                    field: "templateName",
                    sortable: true,
                },
                {
                    label: this.$t('forms.subject'),
                    field: "subject",
                    sortable: false,
                },
                {
                    label: this.$t('forms.createdAt'),
                    field: "createdAt",
                    sortable: true,
                },
                {
                    label: this.$t('forms.read'),
                    field: "read",
                    sortable: true,
                },
                {
                    label: this.$t('forms.sent'),
                    field: "status",
                    sortable: true,
                },
                {
                    label: this.$t('forms.actions'),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
        };
    },
    created() {
        this.getEnumList("email_template_name", this.filterOptions);
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
                templateName: this.emailTemplate,
                recipientEmails: getUserData()["email"]
            };
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            this.$Emails.getCollection({params}).then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.sort = sort;
            this.sortable.order = order;

            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        statusClass(status) {
            let className = 'danger'
            if (status === 'SUCCESS') {
                className = 'success'
            }
            return className
        },
        viewedClass(status) {
            let className = 'danger'
            if (status) {
                className = 'success'
            }
            return className
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        filterSelectChange(emailTemplate) {
            this.emailTemplate = emailTemplate.id
            this.load()
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>


<template>
    <div>
        <title-bar :title="$t('navigations.holiday')"/>
        <div v-if="rows" class="custom-table">
            <table-actions
                :actions="['search', 'addBtn', 'perPage']"
                :addBtnText="$t('messages.holidayCreate')"
                :addBtnLink="$helper.getAddUrl('holiday-requests')"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:createdAt="data">
                    {{ $helper.formatDate(data.value.createdAt) }}
                    {{ $helper.formatTime(data.value.createdAt) }}
                </template>
                <template v-slot:employee="data">
                    {{
                        data.value.employee.givenName + " " + data.value.employee.familyName
                    }}
                </template>
                <template v-slot:purpose="data">
                    {{ data.value.purpose }}
                </template>
                <template v-slot:fromDate="data">
                    {{ $helper.formatDate(data.value.fromDate) }}
                    {{ $helper.formatTime(data.value.fromDate) }}
                </template>
                <template v-slot:toDate="data">
                    {{ $helper.formatDate(data.value.toDate) }}
                    {{ $helper.formatTime(data.value.toDate) }}
                </template>
                <template v-slot:readBy="data">
          <span v-if="'readBy' in data.value">
            {{
                  data.value.readBy.givenName + " " + data.value.readBy.familyName
              }}
          </span>
                </template>
                <template v-slot:changedStatusBy="data">
          <span v-if="'changedStatusBy' in data.value">
            {{
                  data.value.changedStatusBy.givenName + " " + data.value.changedStatusBy.familyName
              }}
          </span>
                </template>
                <template v-slot:status="data">
                    <b-badge :variant="holidayStatusClass(data.value.status)">{{ data.value.status }}</b-badge>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn
                            :path="$helper.getEditUrl('holiday-requests', data.value.id)"
                        />
                        <delete-btn @pressDelete="deleteHoliday(data.value['@id'])"/>
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>
    </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
    name: "AdminHolidayListing",
    components: {
        TableLite,
        TablePagination,
        TableActions,
        EditBtn,
        DeleteBtn,
        TitleBar
    },
    props: {
        widget: {type: Boolean, default: false}
    },
    mixins: [ResourceUtils],
    data() {
        return {
            search: "",
            isLoading: false,
            rows: null,
            perPage: this.widget ? 5 : this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            sortable: {
                order: "createdAt",
                sort: "desc",
            },
            columns: [
                {
                    label: this.$t("forms.created"),
                    field: "createdAt",
                    sortable: true,
                },
                {
                    label: this.$t("forms.purpose"),
                    field: "purpose",
                    sortable: false,
                },
                {
                    label: this.$t("forms.fromDate"),
                    field: "fromDate",
                    sortable: true,
                },
                {
                    label: this.$t("forms.toDate"),
                    field: "toDate",
                    sortable: true,
                },
                {
                    label: this.$t("forms.employee"),
                    field: "employee",
                    sortable: true,
                },
                {
                    label: this.$t("forms.readBy"),
                    field: "readBy",
                    sortable: false,
                },
                {
                    label: this.$t("forms.changedStatusBy"),
                    field: "changedStatusBy",
                    sortable: false,
                },
                {
                    label: this.$t("forms.status"),
                    field: "status",
                    sortable: true,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                },
            ],
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
            };
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            this.$HolidayRequests.getCollection({params}, 'holiday_list').then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setSort(offset, limit, order, sort) {
            this.sortable.order = order;
            this.sortable.sort = sort;
            if (order === 'employee') {
                this.sortable.order = order + '.familyName';
            }
            this.load();
        },
        onChangePage(page) {
            this.currentPage = page;
            this.load();
        },
        deleteHoliday(url) {
            this.deleteByUrl(
                this.$HolidayRequests,
                url,
                this.$t("messages.holidayDeleted"),
                null,
                this.load,
                this.error
            );
        },
        holidayStatusClass(status) {
            let className = 'danger'
            if (status === 'NEW') {
                className = 'primary'
            }
            if (status === 'APPROVED') {
                className = 'success'
            }
            if (status === 'DECLINED') {
                className = 'danger'
            }
            return className
        },
        inputChange(value) {
            this.currentPage = 1;
            this.search = value;
            this.load();
        },
        onPageChange() {
            this.load();
        },
        perPageChange(num) {
            this.currentPage = 1;
            this.perPage = num;
            this.load();
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
</style>

<template>
    <b-card :header="$t('navigations.holiday')" header-tag="strong" class="holiday-dashboard">
        <holiday-table :widget="true"/>
    </b-card>
</template>

<script>
import HolidayTable from '@/views/Admin/HolidayRequests/list.vue'

export default {
    components: {
        HolidayTable
    }
};
</script>

<template>
    <b-card :header="$t('navigations.links')" header-tag="strong" class="links">
      <a href="https://aero.moodlecloud.com/login/index.php" target="_blank" class="btn btn-gray">Training page</a>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLScRs572CC2IMvPpXvybBrTe7OLMNVcz249TxTTJIlwiBVi08A/viewform" target="_blank" class="btn btn-gray">Confidential report</a>
      <a href="https://ims.aei.sk/accounting/receipts" target="_blank" class="btn btn-primary">Receipts</a>
    </b-card>
  </template>

  <script>

  </script>

  <style>
  </style>

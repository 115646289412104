<template>
    <b-card :header="$t('navigations.receipts')" header-tag="strong" class="receipts-dashboard">
        Please use <a href="https://ims.aei.sk/accounting/receipts" target="_blank">the receipts on old IMS</a>
        <!-- <receipts-table :widget="true"/> -->
    </b-card>
</template>

<script>
// import ReceiptsTable from '@/views/Admin/Receipts/list.vue'

export default {
    components: {
        // ReceiptsTable
    }
};
</script>

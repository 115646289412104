<template>
    <router-link :to="path" class="custom-btn" :title="title">
        <BootstrapIcon :icon="icon" size="1x"/>
    </router-link>
</template>

<script>
export default {
    name: "CustomBtn",
    props: {
        path: {type: String, default: ""},
        title: {type: String, default: ""},
        icon: {type: String, default: ""},
    },
};
</script>

<template>
    <b-card :header="$t('navigations.ocr')" header-tag="strong" class="ocr-dashboard">
        <ocr-table :widget="true"/>
    </b-card>
</template>

<script>
import OcrTable from '@/views/Admin/Forms/Ocr/list.vue'

export default {
    components: {
        OcrTable
    }
};
</script>

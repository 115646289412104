<template>
    <b-card :header="$t('navigations.emails')" header-tag="strong" class="email-dashboard">
        <email-table :widget="true"/>
    </b-card>
</template>

<script>
import EmailTable from '@/views/Admin/Emails/list.vue'

export default {
    components: {
        EmailTable
    }
};
</script>

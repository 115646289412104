<template>
  <b-card :header="$t('navigations.readAndUnderstand')" header-tag="strong" class="read-dashboard">
    <read-table :acknowledgedOnly="true" :widget="true"  />
  </b-card>
</template>

<script>
import ReadTable from '@/views/Admin/ReadAndUnderstand/list.vue'
export default {
    components: {
        ReadTable
    }
};
</script>

<style>
</style>

<template>
    <b-card :header="$t('forms.news')" header-tag="strong" class="banner-widget">
        <div v-if="banners">
            <div
                v-for="(item, index) in banners"
                :key="index"
                :class="'alert ' + levelClass(item.level)"
            >
                <BootstrapIcon icon="info-circle" size="2x"/>
                <strong>&nbsp;&nbsp;{{ item.name }}</strong
                >
                <br/>
                {{ item.description }}
            </div>
        </div>
    </b-card>
</template>

<script>
export default {
    data() {
        return {
            banners: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        levelClass(status) {
            let className = 'alert-danger'
            if (status === 'NOTICE') {
                className = 'alert-info'
            }
            if (status === 'WARNING') {
                className = 'alert-warning'
            }
            return className
        },
        load() {
            const params = {
                onOff: true,
                "fromDate[before]": new Date(),
                "toDate[after]": new Date(),
                pagination: false,
            };
            this.$Banners.getCollection({params}).then((response) => {
                this.banners = response.data["hydra:member"];
            });
        },
    },
};
</script>

<style>
</style>
